// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

import PropTypes from 'prop-types';
import { NumericFormat } from 'react-number-format';
// Data
import sellerPackagesTableData from "./data/sellerPackagesTableData";
import React, {useEffect, useState} from "react";
import PackagesService from "../../services/package-service";
import MDAlert from "../../components/MDAlert";
import TextField from "@mui/material/TextField";
import {Autocomplete, FormControl, FormControlLabel, FormGroup, InputLabel, MenuItem, Select} from "@mui/material";
import MDButton from "../../components/MDButton";
import packageService from "../../services/package-service";
import Swal from "sweetalert2";
import DataTable from "examples/Tables/DataTable";
import Checkbox from '@mui/material/Checkbox';

function Packages() {
    
    const [priceRange, setPriceRange] = useState(false);
    const [tableData, setTableData] = useState({ columns: [], rows: [] });
    const [sellerPackage, setSellerPackage] = useState({ price : '', packageId : null});
    const [prices, setPrices] = useState([]);
    const [packages, setPackages] = useState([]);

    const fetchSellerPackages = async () => {
        const response = await PackagesService.sellerPackages();
        
        const { columns, rows } = sellerPackagesTableData(response.data); // Assuming orderTableData processes the response
        setTableData({ columns, rows });
    };
    
    useEffect(() => {
        fetchSellerPackages();
        fetchPackages();
    }, []);

    const sellerPackageHandler = (e) => {
      setSellerPackage({
        ...sellerPackage,
        [e.target.name]: e.target.value // Use square brackets for computed property names
      });
    };
   
    const pricesHandler = (e, rangeId = null) => {
      
      const updatedPrices = [...prices]; // Create a copy of the current prices array
  
      // Check if a price entry with the given rangeId already exists
      const existingPriceIndex = updatedPrices.findIndex(price => price.rangeId === rangeId);
  
      if (existingPriceIndex !== -1) {
          // If it exists, update the existing price
          updatedPrices[existingPriceIndex].price = e.target.value.replace(/,/g, '');;
      } else {
        updatedPrices.push({
          price: e.target.value.replace(/,/g, ''),
          rangeId: rangeId,
      });
      }
  
      setPrices(updatedPrices); // Update the state with the modified prices array
  };
  

    const priceRangeHanlder = (e) => {
        
      setPriceRange(e.target.checked);
  };

    const createSellerPackage = async () => {
        try {
                
            let data = {}
            data['package_id'] = sellerPackage.packageId
            
            if(priceRange){

              data['price'] = prices;
            }else{

              console.log('inja miad');
              data['price'] = sellerPackage.price.replace(/,/g, '');
            }

            
            const response = await packageService.addSellerPackages(data);
         
            // Check if the response has a success status
            if (response.status == 'success') {
                Swal.fire({
                    title: "با موفقیت اضافه شد!",
                    text: "لیست خدمات خود را پایین همین صفحه مشاهده کنید!",
                    icon: "success"
                  });
            }
        } catch (error) {
            Swal.fire({
                title: "با موفقیت اضافه شد!",
                text: "لیست خدمات خود را پایین همین صفحه مشاهده کنید!",
                icon: "success"
              }); 
        }

        setSellerPackage({ title:'', price : '', packageId : null})
        
    };

    const fetchPackages = async () => {
        const response = await PackagesService.allPackages();
        setPackages(response.data)
    };

      const NumericFormatCustom = React.forwardRef(
        function NumericFormatCustom(props, ref) {
          const { onChange, ...other } = props;
      
          return (
            <NumericFormat
              {...other}
              getInputRef={ref}
              onValueChange={(values) => {
                onChange({
                  target: {
                    name: props.name,
                    value: values.value,
                  },
                });
              }}
              thousandSeparator
              valueIsNumericString
              prefix="$"
            />
          );
        },
      );
      
      NumericFormatCustom.propTypes = {
        name: PropTypes.string.isRequired,
        onChange: PropTypes.func.isRequired,
      };


      const { columns, rows } = tableData;
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
            <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
                display="flex"
                justifyContent="space-between"
              >
                <MDBox>
                    <MDTypography variant="h6" color="white">
                        ایجاد خدمات جدبد
                    </MDTypography>
                </MDBox>
              </MDBox>
                <MDBox 
                    p={2}
                    display="flex"
                    flexDirection="column"
                    alignItems="flex-start"
                    height="50%"
                    width="100%">
                    <FormControl fullWidth>     
                    <MDTypography variant="body2" color="text" ml={1} fontWeight="regular">
                      پکیج
                   </MDTypography>                   
                    <Select
                        id="demo-simple-select"
                        name='packageId'
                        MenuProps={{
                            PaperProps: {
                                style: {
                                    maxHeight: 200, // Set your desired max height here
                                    overflowY: 'auto', // Enable vertical scrolling
                                },
                            },
                        }}            
                        value={sellerPackage.packageId}
                        onChange={sellerPackageHandler}
                        >
                   {packages.map((packageItem) => (
                        <MenuItem key={packageItem.id} value={packageItem.id}>
                            {packageItem.desc}
                        </MenuItem>
                    ))}           
                    </Select>
                    </FormControl>
                </MDBox>
                <MDBox
                    p={2}
                    display="flex"
                    flexDirection="column"
                    alignItems="flex-start"
                    width="100%">

                  <FormControlLabel control={<Checkbox onChange={priceRangeHanlder} checked={priceRange} />} label="قیمت بازه ای" />
                </MDBox>

                {priceRange ? (
                  <MDBox
                  p={2}

                  sx={{ display: 'flex' }}
                  width="100%">
                  <FormControl    sx={{ mx: '8px' }}>
                      <NumericFormat 
                          onChange={(e) => pricesHandler(e, 1)}
                          name="price" customInput={TextField} value={sellerPackage.price} label="1 تا 20 (به تومان)" allowLeadingZeros thousandSeparator="," />
                  </FormControl>
                  <FormControl  sx={{ mx: '8px' }}>
                  <NumericFormat 
                      onChange={(e) => pricesHandler(e, 2)}
                          name="price" customInput={TextField} value={sellerPackage.price} label="21 تا 50 (به تومان)" allowLeadingZeros thousandSeparator="," />
                  </FormControl>
                  <FormControl  sx={{ mx: '8px' }}>
                  <NumericFormat 
                      onChange={(e) => pricesHandler(e, 3)}
                        name="price" customInput={TextField} value={sellerPackage.price} label="51تا 200 (به تومان)" allowLeadingZeros thousandSeparator="," />
                  </FormControl>
                  <FormControl  sx={{ mx: '8px' }}>
                      
                      <NumericFormat 
                      onChange={(e) => pricesHandler(e, 4)}
                         name="price" customInput={TextField} value={sellerPackage.price} label="201 تا 500 (به تومان)" allowLeadingZeros thousandSeparator="," />
                  </FormControl>
                  <FormControl  sx={{ mx: '8px' }}>
                  <NumericFormat 
                      onChange={(e) => pricesHandler(e, 5)}
                      name="price" customInput={TextField} value={sellerPackage.price} label="501 تا 1000 (به تومان)" allowLeadingZeros thousandSeparator="," />
                  </FormControl>
                  <FormControl  sx={{ mx: '8px' }}>
                      
                      <NumericFormat 
                      onChange={(e) => pricesHandler(e, 6)}
                         name="price" customInput={TextField} value={sellerPackage.price} label="1000 به بالا (به تومان)" allowLeadingZeros thousandSeparator="," />
                  </FormControl>



                      
                      
                   
                      
              </MDBox>
                ) : (<MDBox
                    p={2}
                    display="flex"
                    flexDirection="column"
                    alignItems="flex-start"
                    width="100%">
                    <FormControl fullWidth>
                        
                        <NumericFormat onChange={sellerPackageHandler}
                            name="price" customInput={TextField} value={sellerPackage.price} label="قیمت (به تومان)" allowLeadingZeros thousandSeparator="," />
                    </FormControl>
                </MDBox>)}
                

                <MDBox
                    p={3}
                    display="flex"
                    flexDirection="column"
                    alignItems="flex-start"
                    width="100%">
                    <MDButton 
                    fullWidth
                      color='info'
                       onClick={createSellerPackage} 
                       loading>
                        ایجاد
                    </MDButton>
                </MDBox>
            </Card>
          </Grid>

          <Grid item xs={12}>
                <Card>
                    <MDBox
                        mx={2}
                        mt={-3}
                        py={3}
                        px={2}
                        variant="gradient"
                        bgColor="info"
                        borderRadius="lg"
                        coloredShadow="info"
                        display="flex"
                        justifyContent="space-between"
                    >
                        <MDBox>
                            <MDTypography variant="h6" color="white">
                                خدمات
                            </MDTypography>
                        </MDBox>
                    </MDBox>
                    <MDBox pt={3}>
                        <DataTable
                            table={{ columns, rows }}
                            isSorted={false}
                            entriesPerPage={false}
                            showTotalEntries={false}
                            noEndBorder
                        />
                    </MDBox>
                </Card>
            </Grid>
        </Grid>

      </MDBox>

    </DashboardLayout>
  );
}

export default Packages;
