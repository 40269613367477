/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
/**
 =========================================================
 * Material Dashboard 2 React - v2.1.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-react
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// Material Dashboard 2 React components

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import MDBadge from "components/MDBadge";

// Images
import team2 from "assets/images/team-2.jpg";
import team3 from "assets/images/team-3.jpg";
import team4 from "assets/images/team-4.jpg";
import Grid from "@mui/material/Grid";
import MDButton from "../../../components/MDButton";
import {Link} from "react-router-dom";
import {Dialog, Modal} from "@mui/material";
import {numberFormat} from "../../../services/helperFunctions";

export default function data(data) {

    const Author = ({ image, name, mobile }) => (
        <MDBox display="flex" alignItems="center" lineHeight={1}>
            <MDAvatar src={image} name={name} size="sm" />
            <MDBox ml={2} lineHeight={1}>
                <MDTypography display="block" variant="button" fontWeight="medium">
                    {name}
                </MDTypography>
                <MDTypography variant="caption">{mobile}</MDTypography>
            </MDBox>
        </MDBox>
    );
    const PackageType = ({ category, sellerPackage , description, fileCount, copyCount , totalPrice}) => (
        <MDBox lineHeight={1} textAlign="left">
            <MDTypography display="block" variant="caption" color="text" fontWeight="medium">
                {' دسته بندی : '} {category}
            </MDTypography>
            <MDTypography display="block" variant="caption" color="text" fontWeight="medium">
                {' پکیج : '} {sellerPackage}
            </MDTypography>
            <MDTypography display="block" variant="caption" color="text" fontWeight="medium">
                {'تعداد : '}{copyCount}
            </MDTypography>
            <MDTypography display="block" variant="caption" color="text" fontWeight="medium">
                {'توضیحات : '}{description}
            </MDTypography>
            <MDTypography display="block" variant="caption" color="text" fontWeight="medium">
                {'تعداد صفحات : '}{fileCount}
            </MDTypography>

            <MDTypography display="block" variant="caption" color="text" fontWeight="medium">
                {'قیمت : '}{<MDBadge badgeContent={numberFormat(totalPrice)+' تومان'}
            color='info'

            variant="gradient" size="lg"/>}
            </MDTypography>

            
        </MDBox>
    );

    const orderItemHandler = (orderItems) => {
        let count = orderItems.length;
    
        return orderItems.map(item => {
            let price = item.saler_package.price?.price ?? 0;
            let totalPrice = price * (item.file_page_count ?? 1) * (item.count ?? 1);
    
            return ( // Add return here
                <Grid key={item.id} item xs={12 / count}>
                    <PackageType 
                        category={item.saler_package.package.category.title} 
                        sellerPackage={item.saler_package.title} 
                        description={item.saler_package.description} 
                        fileCount={item.file_page_count ? item.file_page_count.toString() : ''} 
                        copyCount={item.count.toString()} 
                        totalPrice={totalPrice} 
                    />
                </Grid>
            );
        });
    };
    
    

    const rows = data.map(order => ({
        author: <Author image={team2} name={order.client.name+' '+order.client.lastName} mobile={order.client.mobile}/>,
            function:
           <Grid container spacing={2}>
               {orderItemHandler(order.order_items)}
            </Grid>
        ,
        status: (
            <MDBox display='block'>
                <MDBox ml={-1}>
                    <MDBadge badgeContent={order.status}
                             color={order.status === "تحویل داده شد" ? "success" : "warning"}

                             variant="gradient" size="lg"/>
                </MDBox>
            </MDBox>
        ),
        employed: (
            <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
                {order.creation_date}
            </MDTypography>
        ),
        action: (
            order.status === 'تحویل داده شد' ? (
                <MDButton
                    component={Link}
                    to={"/order/"+order.id}
                    href={(e) => e.preventDefault()}
                    target=""
                    rel="noreferrer"
                    size="small"
                    variant="outlined"
                    color="info"
                >
                    جزییات
                </MDButton>

            ) : (
                <MDButton
                    component={Link}
                    to={"/order/"+order.id}
                    href={(e) => e.preventDefault()}
                    target=""
                    rel="noreferrer"
                    size="small"
                    variant="outlined"
                    color="info"
                >
                    شروع
                </MDButton>
            )
        ),
    }))

    return {
        columns: [
            { Header: "کاربر", accessor: "author", width: "15%", align: "left" },
            { Header: "سفارش", accessor: "function", align: "left" },
            { Header: "وضعیت", accessor: "status", align: "center" },
            { Header: "تاریخ", accessor: "employed", align: "center" },
            { Header: "عملیات", accessor: "action", align: "center" },
        ],

        rows: rows,
    };
}
