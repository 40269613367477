import React, { useRef, useState, useEffect } from 'react';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import markerIcon from 'leaflet/dist/images/marker-icon.png';
import markerIcon2x from 'leaflet/dist/images/marker-icon-2x.png';
import markerShadow from 'leaflet/dist/images/marker-shadow.png';

delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
    iconRetinaUrl: markerIcon2x,
    iconUrl: markerIcon,
    shadowUrl: markerShadow,
});
function MDmapChooser({ onLocationChange , location}) {
    const [selectedLocation, setSelectedLocation] = useState({ lat: null, lng: null });
    const [marker, setMarker] = useState(null);
    const mapContainer = useRef(null);
    const mapInstance = useRef(null); // <-- Use this ref to store the map instance
    
    useEffect(() => {
       
    
        if (!mapInstance.current) {
            mapInstance.current = L.map(mapContainer.current).setView([32.662899, 51.66694], 12.8);
            L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
                maxZoom: 22,
            }).addTo(mapInstance.current);
        }

        const onMapClick = (e) => {
            setSelectedLocation({
                lat: e.latlng.lat,
                lng: e.latlng.lng
            })

            ;


            // Remove the previous marker if it exists
            if (marker) {
                mapInstance.current.removeLayer(marker);
                setMarker(null);
            }

            // Place a new marker at the clicked location and save it in the marker state
            // const newMarker = L.marker([e.latlng.lat, e.latlng.lng]).addTo(mapInstance.current);
            // setMarker(newMarker);
        };

        mapInstance.current.on('click', onMapClick);

        onLocationChange(selectedLocation)
        return () => {
            // Cleanup the click event when the component unmounts
            mapInstance.current.off('click', onMapClick);
        };

    }, [marker]);
    //
    useEffect(() => {
        if (location && location.lat && location.lng){

            const defaultMarker = L.marker([location.lat, location.lng]).addTo(mapInstance.current);
            setMarker(defaultMarker);
        }else{

            const defaultMarker = L.marker(['32.6722817166201', '51.671533584514734']).setIcon();
            setMarker(defaultMarker);
        }

        
    }, [location]);


    return (
        <div>
            <div ref={mapContainer} style={{ height: '400px' , borderRadius: '25px'}} ></div>
        </div>
    );
}

export default MDmapChooser;
