/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
/**
 =========================================================
 * Material Dashboard 2 React - v2.1.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-react
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import MDBadge from "components/MDBadge";

// Images
import team2 from "assets/images/team-2.jpg";
import team3 from "assets/images/team-3.jpg";
import team4 from "assets/images/team-4.jpg";
import Grid from "@mui/material/Grid";

import {Link} from "react-router-dom";
import MDButton from "../../../components/MDButton";

export default function data(data) {
    const Author = ({ image, name, mobile }) => (
        <MDBox display="flex" alignItems="center" lineHeight={1}>
            <MDAvatar src={image} name={name} size="sm" />
            <MDBox ml={2} lineHeight={1}>
                <MDTypography display="block" variant="button" fontWeight="medium">
                    {name}
                </MDTypography>
                <MDTypography variant="caption">{mobile}</MDTypography>
            </MDBox>
        </MDBox>
    );

    console.log(data)
    const PackageType = ({ category, sellerPackage, fileCount, copyCount }) => (
        <MDBox lineHeight={1} textAlign="left" borderRadius={9} p={3} bgColor='light'>
            <MDTypography mb={2} display="block" variant="body" color="text" fontWeight="medium">
                {' دسته بندی : '} {category}
            </MDTypography>
            <MDTypography mb={2} display="block" variant="body" color="text" fontWeight="medium">
                {' پکیج : '} {sellerPackage}
            </MDTypography>
            <MDTypography mb={2} display="block" variant="body" color="text" fontWeight="medium">
                {'تعداد : '}{copyCount}
            </MDTypography>
            <MDTypography mb={2} display="block" variant="body" color="text" fontWeight="medium">
                {'تعداد صفحات فایل : '}{fileCount}
            </MDTypography>
        </MDBox>
    );

    const orderItemHandler = (orderItems) => {
        let count = orderItems.length
        return orderItems.map(item => (
            <Grid key={item.id} item xs={12/count}>
                    <PackageType category={item.saler_package.package.category.title} sellerPackage={item.saler_package.title}  fileCount={item.file_page_count ? item.file_page_count.toString() : 0} copyCount={item.count.toString()} />
            </Grid>
        ));
    };

    const rows = data.map(order => ({
        function:
           <Grid container spacing={2}>
               {orderItemHandler(order.order_items)}
            </Grid>
        ,
        employed: (
            <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
                {order.creation_date}
            </MDTypography>
        ),
        action: (
            <MDButton
                component={Link}
                to={"/order/"+order.id}
                href={(e) => e.preventDefault()}
                target=""
                rel="noreferrer"
                size="small"
                variant="outlined"
                color="info"
            >
                شروع
            </MDButton>

        ),
    }))

    return {
        columns: [
            { Header: "نوع سفارش", accessor: "function", align: "left" },
            { Header: "تاریخ", accessor: "employed", align: "center" },
            { Header: "عملیات", accessor: "action", align: "center" },
        ],

        rows: rows,
    };
}
