

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";

// Images
import {numberFormat} from "../../../services/helperFunctions";
import MDButton from "components/MDButton";
import SimpleDialogDemo from "../SimpleDialogDemo";

export default function data(data) {
     


    const rows = data.map(sellerPackage => ({
          title: (
            <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
                  {sellerPackage.range && sellerPackage.range.title + '----'}  {sellerPackage.title}
            </MDTypography>
        ),
        price: (
            <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
                {numberFormat(parseInt(sellerPackage.price.price))+' تومان'}
            </MDTypography>
        ),
        // category: (
        //     <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
        //         {sellerPackage.package.category.title}
        //     </MDTypography>
        // ),
        // package: (
        //     <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
        //         {sellerPackage.package.title}
        //     </MDTypography>
        // ),
        action: (
            <MDBox
            p={3}
            display="flex"
            flexDirection="column"
            alignItems="flex-start"
            width="100%">
                <SimpleDialogDemo sellerPackage={sellerPackage}/>
        </MDBox>
        ),
    }))

    return {
        columns: [
            { Header: "عنوان", accessor: "title", width : '20%', align: "center" },
            { Header: "قیمت", accessor: "price", width : '20%', align: "center" },
            // { Header: "دسته بندی", accessor: "category", width : '20%', align: "center" },
            // { Header: "خدمت", accessor: "package", width : '20%', align: "center" },
            { Header: "عملیات", accessor: "action", width : '20%', align: "center" },
        ],

        rows: rows,
    };
}
