// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";

// Data
import invoiceTableData from "./data/invoiceTableData";
import {useEffect, useState} from "react";
import authService from "../../services/auth-service";
import {numberFormat} from "../../services/helperFunctions";
import MDAlert from "../../components/MDAlert";
import orderService from "../../services/order-service";


function Invoices() {

    const [tableData, setTableData] = useState({ columns: [], rows: [] });
    const [credit, setCredit] = useState(0);
    const [amount, setAmount] = useState('');
    const [notification, setNotification] = useState({color : null, title:''});

    const fetchData = async () => {
        const response = await authService.getProfile();
        setCredit(response.data.credit)
    };
    const fetchInvoices = async () => {
        const response = await orderService.invoices();
        if (response.data.length ===0 ){
            setNotification({color: 'error', title :'هیچ صورت حسابی یافت نشد'})
            document.documentElement.scrollTop = 0;
            document.scrollingElement.scrollTop = 0;
        }
        const { columns, rows } = invoiceTableData(response.data); // Assuming orderTableData processes the response
        setTableData({ columns, rows });
    };

    useEffect(() => {

        // Call the async function
        fetchData();
        fetchInvoices();
    }, []);

    const { columns, rows } = tableData;
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
          {notification.title && (
              <MDBox mb={6}>
                  <MDAlert  color={notification.color} >
                      <MDTypography variant="body2" color="white">
                          {notification.title}
                      </MDTypography>
                  </MDAlert>
              </MDBox>
          )}
        <Grid container spacing={6}>
    
            <Grid item xs={12}>
                <Card>
                    <MDBox
                        mx={2}
                        mt={-3}
                        py={3}
                        px={2}
                        variant="gradient"
                        bgColor="info"
                        borderRadius="lg"
                        coloredShadow="info"
                        display="flex"
                        justifyContent="space-between"
                    >
                        <MDBox>
                            <MDTypography variant="h6" color="white">
                                صورتحساب ها
                            </MDTypography>
                        </MDBox>

                        <MDBox>
                            <MDTypography variant="h6" color="white">
                                {'اعتبار شما : '+numberFormat(credit)+' تومان'}
                            </MDTypography>
                        </MDBox>
                    </MDBox>
                    <MDBox pt={3}>
                        <DataTable
                            table={{ columns, rows }}
                            isSorted={false}
                            entriesPerPage={false}
                            showTotalEntries={false}
                            noEndBorder
                        />
                    </MDBox>
                </Card>
            </Grid>
        </Grid>

      </MDBox>

    </DashboardLayout>
  );
}

export default Invoices;
