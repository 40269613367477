import HttpService from "./htttp.service";

class PackagesService {
 
  addSellerHour = async (payload) => {

    const sellerHoursEndpoint = 'saler/seller-hours';
    return await HttpService.post(sellerHoursEndpoint,payload);
  };

   
  editSellerHour = async (id, payload) => {

    const sellerHoursEndpoint = 'saler/seller-hours/'+id;
    return await HttpService.put(sellerHoursEndpoint, payload);
  };


   
  sellerHours = async () => {

    const sellerHoursEndpoint = 'saler/seller-hours';
    return await HttpService.get(sellerHoursEndpoint);
  };
}

export default new PackagesService();
