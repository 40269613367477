import {useState, useEffect} from "react";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Header from "layouts/user-profile/Header";
import Grid from "@mui/material/Grid";
import Swal from "sweetalert2";
import { Autocomplete, Card, FormControl, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import sellerServic from "services/seller-servic";
import { Container, Typography, List, ListItem, ListItemText, Divider } from '@mui/material';
import { translateWord } from "services/helperFunctions";
const UserHours = () => {

    const [sellerHours, setSellerHours] = useState([]);
    const [sellerHour, setSellerHour] = useState({  day : null, open_time: null, close_time: null });
    const handleChange = (e) => {
        setSellerHour({
            ...sellerHour,
            [e.target.name]: e.target.value,
        });
    };



    const submitHandler = async (e) => {
        e.preventDefault();
       
        console.log(sellerHour);
        
        try {
            // Call API for update
            const response = await sellerServic.addSellerHour(sellerHour);
            if (response.error) {
                
                Swal.fire({
                    title: "خطا!",
                    text: response.message,
                    icon: "error"
                  });
        
            } else {
                
                Swal.fire({
                    title: "",
                    text: response.message,
                    icon: "success"
                  });

        
            }
        } catch (error) {
            Swal.fire({
                title: "خطا",
                text: 'خطا در به روز رسانی پروفایل!',
                icon: "success"
              });
        }
    };


const columns = [
    { Header: "شنبه", accessor: "Saturday", align: "left" },
    { Header: "یکشنبه", accessor: "Sunday", align: "left" },
    { Header: "دوشنبه", accessor: "Monday", align: "left" },
    { Header: "سه شنبه", accessor: "Tuesday", align: "left" },
    { Header: "چهارشنبه", accessor: "Wednesday", align: "left" },
    { Header: "پنجشنبه", accessor: "Thursday", align: "left" },
    { Header: "جمعه", accessor: "Friday", align: "left" },
]

    // const daysOfWeek = ["Saturday", "Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday"];
    // const dayHours = {};

    // daysOfWeek.forEach(day => {
    //     const hours = sellerHours[day] || [];
        
    //     if (!dayHours[day]) {
    //         dayHours[day] = [];
    //     }

    //     hours.forEach(hour => {
    //         dayHours[day].push(`${hour.open_time} -- ${hour.close_time}`);
    //     });
    // });

    // const finalRows = daysOfWeek.map(day => {
    //     return {
    //         [day]: dayHours[day].length > 0 ? dayHours[day].join('\n') : '' // Join hours or set empty string
    //     };
    // });

    
    
    
  const fetchSellerHours = async () => {
    const userData = localStorage.getItem('token');
    const response = await sellerServic.sellerHours();
    setSellerHours(response.data)
    

};


useEffect(() => {

    fetchSellerHours();
}, []);


    return (

        <DashboardLayout>
            <DashboardNavbar/>
            <Card>
                <MDBox p={3}>
                    <Grid container spacing={3}>
                                    <Grid item xs={12} md={6} lg={3}>
                                        <MDBox>
                                            <FormControl fullWidth>
                                                <MDTypography variant="body2" color="text" ml={1} fontWeight="regular">
                                                  روز هفته
                                                </MDTypography>
                                                <Select
                                                    id="demo-simple-select"
                                                    name='day'
                                                    value={sellerHour.day}
                                                    label="روز هفته"
                                                    onChange={handleChange}
                                                >
                                                    <MenuItem value='Saturday'>شنبه</MenuItem>
                                                    <MenuItem value='Sunday'>یکشنبه</MenuItem>
                                                    <MenuItem value='Monday'>دوشنبه</MenuItem>
                                                    <MenuItem value='Tuesday'>سه شنبه</MenuItem>
                                                    <MenuItem value='Wednesday'>چهارشنبه</MenuItem>
                                                    <MenuItem value='Thursday'>پنجشنبه</MenuItem>
                                                    <MenuItem value='Friday'>جمعه</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </MDBox>
                                    </Grid>
                                    <Grid item xs={12} md={6} lg={3}>
                                        <MDBox  >
                                                <MDTypography variant="body2" color="text" ml={1} fontWeight="regular">
                                                ساعت شروع
                                                </MDTypography>
                                                <MDBox mb={2} width="100%">
                                                    <TextField
                                                                    
                                                        name="open_time"
                                                        type="time"
                                                        value={sellerHour.open_time}
                                                        onChange={handleChange}
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                        inputProps={{
                                                            step: 300, // 5 min
                                                        }}
                                                        fullWidth
                                                    />
                                                </MDBox>
                                            </MDBox>
                                    </Grid>
                                    <Grid item xs={12} md={6} lg={3}>
                                        <MDBox  >
                                                <MDTypography variant="body2" color="text" ml={1} fontWeight="regular">
                                                ساعت پایان
                                                </MDTypography>
                                                <MDBox mb={2} width="100%">
                                                    <TextField
                                                                    
                                                        name="close_time"
                                                        type="time"
                                                        value={sellerHour.close_time}
                                                        onChange={handleChange}
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                        inputProps={{
                                                            step: 300, // 5 min
                                                        }}
                                                        fullWidth
                                                     />
                                                </MDBox>
                                            </MDBox>
                                    </Grid>
                                <Grid item xs={12} md={6} lg={3}>
                                    <MDBox mt={3}>
                                        <MDButton variant="gradient" 
                                                onClick={submitHandler} fullWidth color="info" type="submit" loading>
                                            ذخیره
                                        </MDButton>
                                    </MDBox>
                                </Grid>
                    </Grid>
                </MDBox>
            </Card>


            <MDBox mt={3}>
                <Card sx={{ width: '100%' }}>
                <Container>
                    <MDBox pt={3}>
                        <MDTypography variant="body2" color="text" fontWeight="bold">
                            ساعات کاری
                        </MDTypography>
                    </MDBox>
                    <MDBox p={2}>
                        <List>
                                    {Object.entries(sellerHours).map(([day, hours]) => (
                                        <div key={day}>
                                            <ListItem>
                                                <ListItemText primary={translateWord(day)} />
                                            </ListItem>
                                            {hours.map((hour, index) => (
                                                <ListItem key={index}>
                                                    <ListItemText
                                                        secondary={`${hour.open_time.substring(0, 5)} - ${hour.close_time.substring(0, 5)}`}
                                                    />
                                                </ListItem>
                                            ))}
                                            <Divider />
                                        </div>
                                    ))}
                        </List>     
                    </MDBox>           
                </Container>
                </Card>
            </MDBox>
        </DashboardLayout>
    );
};

export default UserHours;
