import { forwardRef, useState } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// Custom styles for MDButton
import MDButtonRoot from "components/MDButton/MDButtonRoot";

// Material Dashboard 2 React contexts
import { useMaterialUIController } from "context";
import { CircularProgress } from "@mui/material";

const MDButton = forwardRef(
  ({ color, variant, size, circular, iconOnly, children, loading, ...rest }, ref) => {
    const [controller] = useMaterialUIController();
    const { darkMode } = controller;
    const [isLoading, setIsLoading] = useState(false);

    const handleClick = async (event) => {
      if (rest.onClick) {
        
        if (loading) {
          setIsLoading(true);
        }

        console.log('arrrr');
        try {
          await rest.onClick(event);
        } catch (error) {

        }finally {

          if (loading) {

          console.log('arrrr2');
            setIsLoading(false);
          }
        }
      }
    };

    return (
      <MDButtonRoot
      {...rest}
      ref={ref}
      color="primary"
      variant={variant === "gradient" ? "contained" : variant}
      size={size}
      onClick={handleClick}
      disabled={isLoading} // Disable button while loading
      ownerState={{ color, variant, size, circular, iconOnly, darkMode }}
    >
      {isLoading ? (
        <CircularProgress size={24} color="inherit" /> // Show loading spinner
      ) : (
        children
      )}
    </MDButtonRoot>
    );
  }
);

// Setting default values for the props of MDButton
MDButton.defaultProps = {
  size: "medium",
  variant: "contained",
  color: "white",
  circular: false,
  loading: false,
  iconOnly: false,
};

// Typechecking props for the MDButton
MDButton.propTypes = {
  size: PropTypes.oneOf(["small", "medium", "large"]),
  variant: PropTypes.oneOf(["text", "contained", "outlined", "gradient"]),
  color: PropTypes.oneOf([
    "white",
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "light",
    "dark",
  ]),
  circular: PropTypes.bool,
  iconOnly: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

export default MDButton;
