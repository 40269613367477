

// Material Dashboard 2 React layouts
import Dashboard from "layouts/dashboard";
import Orders from "layouts/orders";
import Invoices from "layouts/invoices";
import Order from "layouts/order";
import Todo from "layouts/todoes";
import Packages from "layouts/packages";
import UserProfile from "layouts/user-profile";
import Login from "auth/login";
import LocalMallRoundedIcon from '@mui/icons-material/LocalMallRounded';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import PaymentIcon from '@mui/icons-material/Payment';
// @mui icons
import Icon from "@mui/material/Icon";
import UserHours from "layouts/user-hours";

const routes = [
  {
    type: "collapse",
    name: "داشبورد",
    key: "dashboard",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/dashboard",
    component: <Dashboard />,
  },
  {
        type: "collapse",
        name: "خدمات",
        key: "SellerPackages",
        icon: <NotificationsActiveIcon></NotificationsActiveIcon>,
        route: "/packages",
        component: <Packages />,
    },
  {
        type: "collapse",
        name: "لیست کارها",
        key: "Todo",
        icon: <NotificationsActiveIcon></NotificationsActiveIcon>,
        route: "/todo",
        component: <Todo />,
    },
    {
        type: "collapse",
        name: "صورتحساب ها",
        key: "Invoices",
        icon: <PaymentIcon></PaymentIcon>,
        route: "/invoices",
        component: <Invoices />,
    },
  {
    type: "collapse",
    name: "سفارشات",
    key: "orders",
    icon: <LocalMallRoundedIcon></LocalMallRoundedIcon>,
    route: "/orders",
    component: <Orders />,
  },
  // {
  //   type: "collapse",
  //   name: "Tables",
  //   key: "tables",
  //   icon: <Icon fontSize="small">table_view</Icon>,
  //   route: "/tables",
  //   component: <Tables />,
  // },
  // {
  //   type: "collapse",
  //   name: "Client",
  //   key: "client",
  //   icon: <Icon fontSize="small">table_view</Icon>,
  //   route: "/client",
  //   component: <Client />,
  // },
  // {
  //   type: "collapse",
  //   name: "Billing",
  //   key: "billing",
  //   icon: <Icon fontSize="small">receipt_long</Icon>,
  //   route: "/billing",
  //   component: <Billing />,
  // },
  // {
  //   type: "collapse",
  //   name: "RTL",
  //   key: "rtl",
  //   icon: <Icon fontSize="small">format_textdirection_r_to_l</Icon>,
  //   route: "/rtl",
  //   component: <RTL />,
  // },
  // {
  //   type: "collapse",
  //   name: "Notifications",
  //   key: "notifications",
  //   icon: <Icon fontSize="small">notifications</Icon>,
  //   route: "/notifications",
  //   component: <Notifications />,
  // },
  // {
  //   type: "collapse",
  //   name: "Profile",
  //   key: "profile",
  //   icon: <Icon fontSize="small">person</Icon>,
  //   route: "/profile",
  //   component: <Profile />,
  // },
  // {
  //   type: "collapse",
  //   name: "Sign In",
  //   key: "sign-in",
  //   icon: <Icon fontSize="small">login</Icon>,
  //   route: "/authentication/sign-in",
  //   component: <SignIn />,
  // },
  {
    type: "examples",
    name: "حساب کاربری",
    key: "user-profile",
    icon: <Icon fontSize="small">person</Icon>,
    route: "/user-profile",
    component: <UserProfile />,
  },
  {
    type: "examples",
    name: "ساعات کاری",
    key: "user-hours",
    icon: <Icon fontSize="small">person</Icon>,
    route: "/user-hours",
    component: <UserHours />,
  },
  // {
  //   type: "examples",
  //   name: "User Management",
  //   key: "user-management",
  //   icon: <Icon fontSize="small">list</Icon>,
  //   route: "/user-management",
  //   component: <UserManagement />,
  // },
  // {
  //   type: "collapse",
  //   name: "Sign Up",
  //   key: "sign-up",
  //   icon: <Icon fontSize="small">assignment</Icon>,
  //   route: "/authentication/sign-up",
  //   component: <SignUp />,
  // },
  {
    type: "auth",
    name: "Login",
    key: "login",
    icon: <Icon fontSize="small">login</Icon>,
    route: "/auth/login",
    component: <Login />,
  },
  // {
  //   type: "auth",
  //   name: "Register",
  //   key: "register",
  //   icon: <Icon fontSize="small">register</Icon>,
  //   route: "/auth/register",
  //   component: <Register />,
  // },
  // {
  //   type: "auth",
  //   name: "Forgot Password",
  //   key: "forgot-password",
  //   icon: <Icon fontSize="small">assignment</Icon>,
  //   route: "/auth/forgot-password",
  //   component: <ForgotPassword />,
  // },
  // {
  //   type: "auth",
  //   name: "Reset Password",
  //   key: "reset-password",
  //   icon: <Icon fontSize="small">assignment</Icon>,
  //   route: "/auth/reset-password",
  //   component: <ResetPassword />,
  // },
  {
    route: "/order/:id",
    component: <Order />,
  },
];

export default routes;
