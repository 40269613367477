import HttpService from "./htttp.service";

class OrderService {

  orders = async (payload) => {

    const ordersEndpoint = 'orders-show';
    return await HttpService.post(ordersEndpoint, payload);
  };

  todos = async (payload) => {

    const ordersEndpoint = 'orders-todos?orderBy=id&sortedBy=desc';
    return await HttpService.post(ordersEndpoint, payload);
  };

  order = async (payload, id) => {
    const orderEndpoint = 'orders/'+id;
    return await HttpService.get(orderEndpoint, payload);
  };
  snappGetRide = async (credentials) => {
    const snappGetRideEndpoint = 'https://customer.snapp-box.com/v1/customer/create_order';
    return await HttpService.post(snappGetRideEndpoint, credentials);
  };

  logout = async () => {
    const logoutEndpoint = 'logout';
    return await HttpService.post(logoutEndpoint);
  };

  forgotPassword = async (payload) => {
    const forgotPassword = 'password-forgot';
    return await HttpService.post(forgotPassword, payload);
  }

  resetPassword = async (credentials) => {
    const resetPassword = 'password-reset';
    return await HttpService.post(resetPassword, credentials);
  }

  getProfile = async() => {
    const getProfile = 'me';
    return await HttpService.get(getProfile);
  }

    invoices = async () => {
    const invoicesEndPoint = "invoices";
    return await HttpService.get(invoicesEndPoint);
  }

    createInvoice = async (newInfo) => {
    const createInvoiceEndPoint = "invoices";
    return await HttpService.post(createInvoiceEndPoint, newInfo);
  }
    cancelOrder = async (id) => {
    const cancelOrderEndPoint = "cancel-order/"+id;
    return await HttpService.post(cancelOrderEndPoint, null);
  }
}

export default new OrderService();
