import HttpService from "./htttp.service";

class PackagesService {
 
  sellerPackages = async () => {

    const sellerPackagesEndpoint = 'saler/packages?orderBy=id&sortedBy=desc';
    return await HttpService.get(sellerPackagesEndpoint);
  };

  allPackages  = async () => {

    const allPackagesEndpoint = 'saler/all-packages';
    return await HttpService.get(allPackagesEndpoint);
  };

    
  categories = async () => {

    const categoriesEndpoint = 'saler/categories';
    return await HttpService.get(categoriesEndpoint);
  };
 
  categoryPackages = async (id) => {

    const categoryPackagesEndpoint = 'saler/categories/'+id+'/packages';
    return await HttpService.get(categoryPackagesEndpoint);
  };

 
  addSellerPackages = async (payload) => {

    const addSellerPackagesEndpoint = 'saler/packages';
    return await HttpService.post(addSellerPackagesEndpoint, payload);
  };
  
  categorySubCategories = async (id) => {

    const categorySubcategoriesEndpoint = 'client/categories/'+id+'/sub-categories';
    return await HttpService.get(categorySubcategoriesEndpoint);
  };

  updateSellerPackages = async (payload, id) => {

    const addSellerPackagesEndpoint = 'saler/packages/'+id;
    return await HttpService.put(addSellerPackagesEndpoint, payload);
  };


  deleteSellerPackages = async (id) => {

    const deleteSellerPackageEndpoint = 'saler/packages/'+id;
    return await HttpService.delete(deleteSellerPackageEndpoint);
  };

}

export default new PackagesService();
