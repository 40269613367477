/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// Material Dashboard 2 React context
import { useMaterialUIController } from "context";
import {Download} from "@mui/icons-material";
import PrintIcon from "@mui/icons-material/Print";
import DeliveryDiningOutlined from "@mui/icons-material/DeliveryDiningOutlined";
import PdfPrint from "layouts/order/Bill/components/pdfPrint";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import {uploadUrl} from "../../../services/helperFunctions";

function Bill({ name, mobile, description, orderItems }) {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;


    const PackageType = ({ category, file, sellerPackage ,description, fileCount, copyCount }) => (
        <MDBox borderRadius={9} p={3} bgColor='light'>
            <MDTypography my={1}  variant="body2" color="text">
                {' پکیج : '} {sellerPackage}
            </MDTypography>
            <MDTypography my={1} variant="body2" color="text" >
                {' دسته بندی : '} {category}
            </MDTypography>
            <MDTypography my={1} variant="body2" color="text">
                {'تعداد : '}{copyCount}
            </MDTypography>
            { file && (
              <>
                <MDTypography my={1} variant="body2" color="text">
                    {'تعداد صفحات : '}{fileCount}
                </MDTypography>
                <MDBox  mt={1} display="flex">
                    <MDButton fullWidth variant="contained" color="info" size="medium"
                              href={file}
                              target="_blank"
                              endIcon={<Download />}>
                        دانلود فایل ضمیمه
                    </MDButton>
                </MDBox>
              </>
           
        )}
        </MDBox>
    );

    const orderItemHandler = (orderItems = []) => {

            let count = orderItems.length
            return orderItems.map(item => (
                <Grid key={item.id} item xs={12} mt={3}>
                    <PackageType file={item.file} category={item.saler_package.package.category.title} sellerPackage={item.saler_package.title} fileCount={item.file_page_count && item.file_page_count.toString()} copyCount={item.count.toString()} />
                </Grid>
            ));

    };

    return (
    <MDBox
      component="li"
      display="block"
      justifyContent="space-between"
      alignItems="flex-start"
      borderRadius="lg"
    >
      <MDBox >
        <MDBox mb={1} display="flex">
            <MDTypography variant="caption">
                توضیحات سفارش :
            </MDTypography>

            <MDTypography lineHeight={1} variant="body2" fontWeight="small">
                    {description ?? '  - '}
            </MDTypography>
        </MDBox>
        <MDBox mb={1} display="flex">
            <MDTypography variant="caption">
                نام :
            </MDTypography>

            <MDTypography lineHeight={1} variant="body2" fontWeight="small">
                    {name ?? '-'}
            </MDTypography>
        </MDBox>
        <MDBox mb={3} display="flex">
          <MDTypography variant="caption">
           شماره تماس مشتری :
          </MDTypography>
          <MDTypography lineHeight={1} variant="body2" fontWeight="small">
              {mobile}
            </MDTypography>
        </MDBox>
        <MDBox lineHeight={0}>
            {orderItemHandler(orderItems)}
        </MDBox>
      </MDBox>
        
    </MDBox>
  );
}

// Setting default values for the props of Bill
Bill.defaultProps = {
  noGutter: false,
};

// Typechecking props for the Bill
Bill.propTypes = {
  name: PropTypes.string.isRequired,
  company: PropTypes.string.isRequired,
  mobile: PropTypes.string.isRequired,
  vat: PropTypes.string.isRequired,
  noGutter: PropTypes.bool,
    file: PropTypes.string,
};

export default Bill;
