export const translateWord = (word) => {
  switch (word){
      case 'user-profile' :
          return 'پروفایل'
      break
      case 'dashboard' :
          return 'داشبورد'
      break
      case 'home' :
          return 'خانه'
      break
      case 'orders' :
          return 'سفارشات'
      break
      case 'order' :
          return 'سفارش'
      break
      case 'create-order' :
          return 'ایجاد سفارش'
      break
      case 'Sunday' :
          return 'یکشنبه'
      break
      case 'Monday' :
          return 'دوشنبه'
      break
      case 'Tuesday' :
          return 'سه شنبه'
      break
      case 'Wednesday' :
          return 'چهارشنبه'
      break
      case 'Thursday' :
          return 'پنجشنبه'
      break
      case 'Friday' :
          return 'جمعه'
      break
      case 'Saturday' :
          return 'شنبه'
      break
      case 'packages' :
          return 'پکیج ها'
      case 'SellerPackages' :
          return 'خدمات دفاتر فنی'


      break
  }
};
// export const uploadUrl = 'http://localhost:8000/upload/files/';

export const uploadUrl = 'https://easychapp.com/api/uploads/';
export const SupportTelegramLink = 'https://t.me/easychapp_support';
export const numberFormat = (number) => {
    const options = { style: 'decimal', minimumFractionDigits: 0, maximumFractionDigits: 0 };

    const formattedNumber = number.toLocaleString('en-US', options);
    return formattedNumber;
};
