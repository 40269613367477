/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
/**
 =========================================================
 * Material Dashboard 2 React - v2.1.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-react
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import MDBadge from "components/MDBadge";

// Images
import {numberFormat} from "../../../services/helperFunctions";

export default function data(data) {
    const Author = ({ image, name, mobile }) => (
        <MDBox display="flex" alignItems="center" lineHeight={1}>
            <MDAvatar src={image} name={name} size="sm" />
            <MDBox ml={2} lineHeight={1}>
                <MDTypography display="block" variant="button" fontWeight="medium">
                    {name}
                </MDTypography>
                <MDTypography variant="caption">{mobile}</MDTypography>
            </MDBox>
        </MDBox>
    );



    const rows = data.map(invoice => ({
        amount: (
            <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
                {numberFormat(parseInt(invoice.amount))+' تومان'}
            </MDTypography>
        ),
        status: (
            <MDBox ml={-1}>
                <MDBadge badgeContent={invoice.status} color="success" variant="gradient" size="sm"/>
            </MDBox>
        ),
        data: (
            <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
                {invoice.date}
            </MDTypography>
        ),
    }))

    return {
        columns: [
            { Header: "مقدار", accessor: "amount", width: "45%", align: "left" },
            { Header: "وضعیت", accessor: "status", align: "center" },
            { Header: "آخرین بروزرسانی", accessor: "data", align: "center" },
        ],

        rows: rows,
    };
}
