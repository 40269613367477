import * as React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import MDButton from 'components/MDButton';
import MDBox from 'components/MDBox';
import Card from "@mui/material/Card";
import MDTypography from "components/MDTypography";
import sellerPackagesTableData from "./data/sellerPackagesTableData";
import { useEffect, useState } from "react";
import PackagesService from "../../services/package-service";
import TextField from "@mui/material/TextField";
import { Autocomplete, FormControl } from "@mui/material";
import packageService from "../../services/package-service";

function SimpleDialog(props) {
  const { onClose, selectedValue, fetchCategoryPackages, categories, basePackages, open } = props;

  
  
  const [amount, setAmount] = useState(selectedValue.price.price);
  const [title, setTitle] = useState(selectedValue.title);
  const [selectedPackage, setSelectedPackage] = useState(Number(selectedValue.package_id));
  const [selectedCategory, setSelectedCategory] = useState(Number(selectedValue.package.category.id));


  const updateSellerPackage = async (id) => {
    let data = {
      // price: amount,
      price : selectedCategory.id != 27 ? amount : 0,
      title: title,
      package_id: selectedPackage,
    };
    try {
      const response = await packageService.updateSellerPackages(data, id);
      if (response.status == 'success') {
        window.location.reload();
        // setAmount('');
        // setTitle('');
        // setSelectedCategory(null);
        // setSelectedPackage(null);
      }
    } catch (error) {
    }
    handleClose()
  };

  const amountHandler = (e) => setAmount(e.target.value);
  const categoryHandler = (e, value) => {
    if (value) {
      fetchCategoryPackages(value.id)
      setSelectedCategory(value.id);
    } else {
      setSelectedPackage(null);
      setSelectedCategory(null);
    }
  };

  const titleHandler = (e) => setTitle(e.target.value);

  const packageHandler = (e, value) => {
    if (value) {
      setSelectedPackage(value.id);
    } else {
      setSelectedPackage(null);
    }
  };

  const submitHandler = () => updateSellerPackage(selectedValue.id);
  const handleClose = () => onClose(selectedValue);

  return (
    <Dialog onClose={handleClose} open={open}   fullWidth>
      <DialogTitle>بروزرسانی پکیج</DialogTitle>
      <List sx={{ pt: 0 }}>
        <ListItem disableGutters>
          <MDBox  sx={{
              display: 'flex',
              flexDirection: 'column',
              width: '100%',
            }}>
          {/* <MDBox p={2} display="flex" flexDirection="column" alignItems="flex-start" width="100%">
              <Autocomplete
              disableClearable
                id="category-autocomplete"
                options={categories}
                sx={{ width: '100%' }}
                onChange={categoryHandler}
                value={categories.find(cat => cat.id === selectedCategory) || null} 
                getOptionLabel={(option) => option.title || 'No Title'}
                renderInput={(params) => <TextField {...params} label="دسته بندی" />}
              />
            </MDBox>

            <MDBox p={2} display="flex" flexDirection="column" alignItems="flex-start" width="100%">
              <Autocomplete
              disableClearable
                id="package-autocomplete"
                options={basePackages}
                sx={{ width: '100%' }}
                onChange={packageHandler}
                value={basePackages.find(pkg => pkg.id === selectedPackage) || null} 
                getOptionLabel={(option) => option.title || 'No Title'}
                renderInput={(params) => <TextField {...params} label="لیست خدمات اصلی" />}
              />
            </MDBox> */}

            <MDBox p={2} display="flex" flexDirection="column" alignItems="flex-start" width="100%">
              <FormControl fullWidth>
                <TextField
                  onChange={titleHandler}
                  value={title}
                  label="عنوان"
                  variant="outlined"
                />
              </FormControl>
            </MDBox>

            {selectedCategory.id != 27 ? (
              <MDBox p={2} display="flex" flexDirection="column" alignItems="flex-start" width="100%">
              <FormControl fullWidth>
                <TextField
                  onChange={amountHandler}
                  value={amount}
                  label="قیمت"
                  variant="outlined"
                />
              </FormControl>
            </MDBox>
            ) : ('')}

            <MDBox p={3} display="flex" flexDirection="column" alignItems="flex-start" width="100%">
              <MDButton
                sx={{
                  width: {
                    xs: '100%', // 100% width on extra small screens
                    lg: '50%'   // 50% width on large screens and above
                  }
                }}
                color='info' 
                onClick={submitHandler}>
                بروزرسانی
              </MDButton>
            </MDBox>
          </MDBox>
        </ListItem>
      </List>
    </Dialog>
  );
}

SimpleDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  selectedValue: PropTypes.object.isRequired, // Change to object
};

export default function SimpleDialogDemo({ sellerPackage }) {
  const [open, setOpen] = React.useState(false);
  const [selectedValue, setSelectedValue] = useState(sellerPackage);
  const [categories, setCategories] = useState([]);
  const [basePackages, setBasePackages] = useState([]);


  const handleClickOpen = () => {
    fetchCategories()
    fetchCategoryPackages(selectedValue.package.category.id)
    setOpen(true)
  };
  const handleClose = (value) => {
    setOpen(false);
    setSelectedValue(value);
  };

  const fetchCategoryPackages = async (id) => {
    const response = await PackagesService.categoryPackages(id);
  
    setBasePackages(response.data);
  };

  const fetchCategories = async () => {
    const response = await PackagesService.categories();
  
    setCategories(response.data);
  };

  const handleDeleteSellerPackage = async () => {
    let id = sellerPackage.id
    console.log(sellerPackage.id);

    
    if(id){
      try {
        const response = await packageService.deleteSellerPackages(id);
        if (response.status == 'success') {
          
        window.location.reload();
          
        }
      } catch (error) {
      }
    }
  
  };



  return (
    <div>
  
      <MDButton
        color='info' onClick={handleClickOpen}>
                        بروزرسانی
      </MDButton>
      <SimpleDialog
      fetchCategoryPackages={fetchCategoryPackages}
      categories={categories}
      basePackages={basePackages}
        selectedValue={selectedValue}
        open={open}
        onClose={handleClose}
      />


  
      <MDButton mx={2} color='error' onClick={handleDeleteSellerPackage}>
                        حذف
      </MDButton>
    </div>
  );
}
