/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";


// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import ReportsBarChart from "examples/Charts/BarCharts/ReportsBarChart";
import ReportsLineChart from "examples/Charts/LineCharts/ReportsLineChart";
import axios  from "axios";
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";

// Data
import reportsBarChartData from "layouts/dashboard/data/reportsBarChartData";
import reportsLineChartData from "layouts/dashboard/data/reportsLineChartData";

// Dashboard components
import Projects from "layouts/dashboard/components/Projects";
import OrdersOverview from "layouts/dashboard/components/OrdersOverview";
import {useEffect, useState} from "react";
import OrderService from "../../services/order-service";

function Dashboard() {
  const { sales, tasks } = reportsLineChartData;
    const [todoCount, setTodoCount] = useState(0);
    const [orderCount, setOrderCount] = useState(0);

    useEffect(() => {

        // Define an async function within the useEffect
        let userData = localStorage.getItem('token'); // Use getItem, not key
        const fetchTodoCount = async () => {
            const response = await OrderService.todos(JSON.stringify(userData));
            setTodoCount(response.data.length)
        };
        const fetchOrderCount = async () => {
            const response = await OrderService.orders(JSON.stringify(userData));
            setOrderCount(response.data.length)

        };

        // Call the async function
        fetchOrderCount();
        fetchTodoCount();

    }, []);
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="error"
                icon="notifications-active-icon"
                title="لیست کارها"
                count={todoCount}
                percentage={{
                  color: "success",
                  amount: "+55%",
                  label: "بیشتر از هفته پیش",
                }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                icon="local_mall"
                title="سفارشات"
                count={orderCount}
                percentage={{
                  color: "success",
                  amount: "+3%",
                  label: "بیشتر از ماه پیش",
                }}
              />
            </MDBox>
          </Grid>
          {/*<Grid item xs={12} md={6} lg={3}>*/}
          {/*  <MDBox mb={1.5}>*/}
          {/*    <ComplexStatisticsCard*/}
          {/*      color="success"*/}
          {/*      icon="store"*/}
          {/*      title="Revenue"*/}
          {/*      count="34k"*/}
          {/*      percentage={{*/}
          {/*        color: "success",*/}
          {/*        amount: "+1%",*/}
          {/*        label: "than yesterday",*/}
          {/*      }}*/}
          {/*    />*/}
          {/*  </MDBox>*/}
          {/*</Grid>*/}
          {/*<Grid item xs={12} md={6} lg={3}>*/}
          {/*  <MDBox mb={1.5}>*/}
          {/*    <ComplexStatisticsCard*/}
          {/*      color="primary"*/}
          {/*      icon="person_add"*/}
          {/*      title="Followers"*/}
          {/*      count="+91"*/}
          {/*      percentage={{*/}
          {/*        color: "success",*/}
          {/*        amount: "",*/}
          {/*        label: "Just updated",*/}
          {/*      }}*/}
          {/*    />*/}
          {/*  </MDBox>*/}
          {/*</Grid>*/}
        </Grid>
        <MDBox mt={4.5}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={4}>
              <MDBox mb={3}>
                {/*<ReportsBarChart*/}
                {/*  color="info"*/}
                {/*  title="website views"*/}
                {/*  description="Last Campaign Performance"*/}
                {/*  date="campaign sent 2 days ago"*/}
                {/*  chart={reportsBarChartData}*/}
                {/*/>*/}
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <MDBox mb={3}>
                {/*<ReportsLineChart*/}
                {/*  color="success"*/}
                {/*  title="daily sales"*/}
                {/*  description={*/}
                {/*    <>*/}
                {/*      (<strong>+15%</strong>) increase in today sales.*/}
                {/*    </>*/}
                {/*  }*/}
                {/*  date="updated 4 min ago"*/}
                {/*  chart={sales}*/}
                {/*/>*/}
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <MDBox mb={3}>
                {/*<ReportsLineChart*/}
                {/*  color="dark"*/}
                {/*  title="completed tasks"*/}
                {/*  description="Last Campaign Performance"*/}
                {/*  date="just updated"*/}
                {/*  chart={tasks}*/}
                {/*/>*/}
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>
        <MDBox>
          {/*<Grid container spacing={3}>*/}
          {/*  <Grid item xs={12} md={6} lg={8}>*/}
          {/*    <Projects />*/}
          {/*  </Grid>*/}
          {/*  <Grid item xs={12} md={6} lg={4}>*/}
          {/*    <OrdersOverview />*/}
          {/*  </Grid>*/}
          {/*</Grid>*/}
        </MDBox>
      </MDBox>
    </DashboardLayout>
  );
}

export default Dashboard;
