
// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import SupportAgentIcon from '@mui/icons-material/SupportAgent';

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import Swal from 'sweetalert2'

import { styled } from '@mui/material/styles';
import Stepper from '@mui/material/Stepper';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import StepLabel from '@mui/material/StepLabel';
import Check from '@mui/icons-material/Check';

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";

// Data
import {useCallback, useEffect, useState} from "react";
import { useParams } from 'react-router-dom';
import OrderService from "../../services/order-service";
import DashboardNavbar from "../../examples/Navbars/DashboardNavbar";
import Icon from "@mui/material/Icon";
import MDTypography from "../../components/MDTypography";
import Divider from "@mui/material/Divider";
import MDButton from "../../components/MDButton";
import {Cancel, DoneAll, Download} from "@mui/icons-material";
import Bill from "../order/Bill";
import DeliveryDiningOutlined from "@mui/icons-material/DeliveryDiningOutlined";
import SnappService from "../../services/snapp-service";
import {count} from "react-table/src/aggregations";
import {Step} from "@mui/material";
import orderService from "../../services/order-service";

function Order() {

    const { id } = useParams();
    const [order, setOrder] = useState([]);
    const [rider, setRider] = useState(false);
    const [client, setClient] = useState([]);
    const [doneSteps, setDoneSteps] = useState([]);
    const [canceled, setCanceled] = useState(false);

    const getOrder = async () => {
        const userData = localStorage.getItem('token'); // Use getItem, not key
        const response = await OrderService.order(JSON.stringify(userData), id);
        setOrder(response.data)
        setDoneSteps(response.doneSteps)

        if(count(response.data.snapp_orders) > 0){
            setRider(true)
        }
        setClient(response.data.client)

    };

    useEffect(() => {
        // Call the async function
        getOrder();

    }, []);

    useEffect(() => {
        if (order.status == 'لغو شد'){
            setCanceled(true)
        }
    }, [order]);

    const steps = [
        'انتخاب نزدیک ترین دفتر فنی',
        'آماده سازی سفارش',
        'در راه است',
        'تحویل داده شد',
    ];
    function QontoStepIcon(props) {
        const { active, completed, className } = props;

        return (
            <QontoStepIconRoot ownerState={{ active }} className={className}>
                {completed ? (
                    <Check className="QontoStepIcon-completedIcon"/>
                ) : (
                    <div className="QontoStepIcon-circle" />
                )}
            </QontoStepIconRoot>
        );
    }
    const QontoConnector = styled(StepConnector)(({ theme }) => ({
        [`&.${stepConnectorClasses.alternativeLabel}`]: {
            top: 10,
            left: 'calc(-50% + 16px)',
            right: 'calc(50% + 16px)',
        },
        [`&.${stepConnectorClasses.active}`]: {
            [`& .${stepConnectorClasses.line}`]: {
                borderColor: '#5bf44a',
            },
        },
        [`&.${stepConnectorClasses.completed}`]: {
            [`& .${stepConnectorClasses.line}`]: {
                borderColor: '#5bf44a',
            },
        },
        [`& .${stepConnectorClasses.line}`]: {
            borderColor: theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
            borderTopWidth: 3,
            borderRadius: 1,
        },
    }));

    const QontoStepIconRoot = styled('div')(({ theme, ownerState }) => ({
        color: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#eaeaf0',
        display: 'flex',
        height: 22,
        alignItems: 'center',
        ...(ownerState.active && {
            color: '#EAEAF0FF',
        }),
        '& .QontoStepIcon-completedIcon': {
            color: '#5bf44a',
            zIndex: 1,
            fontSize: 22,
        },
        '& .QontoStepIcon-circle': {
            width: 12,
            height: 12,
            borderRadius: '50%',
            backgroundColor: 'currentColor',
        },
    }));
    const CustomStyledStepper = styled(Stepper)({
        background: '#afafb5',
        boxShadow: 'none',
    });
    const RiderTimeLine = ({ status }) => (
        <CustomStyledStepper alternativeLabel activeStep={doneSteps} connector={<QontoConnector />}>
            {steps.map((label) => (
                <Step key={label}>
                    <StepLabel StepIconComponent={QontoStepIcon}>{label}</StepLabel>
                </Step>
            ))}
        </CustomStyledStepper>
    );
    const creatSnappOrderHandler = useCallback(() => {

        const fetchData = async () => {

            const response = await SnappService.creatSnappOrder('', id);

            getOrder()
            if(response.success){
                Swal.fire({
                    title: response.message,
                    text: response.message,
                    icon: "success"
                });
            }else{
                Swal.fire({
                    title: response.message,
                    text: response.message,
                    icon: "error"
                });
            }
        };

        // Call the async function
        fetchData();
    }, []);


    const cancelOrder = useCallback(async () => {
        const response = await orderService.cancelOrder(id);

        if (response.success) {
            setCanceled(true)
            Swal.fire({
                title: response.message,
                text: response.message,
                icon: "success"
            });
        } else {
            Swal.fire({
                title: response.message,
                text: response.message,
                icon: "error"
            });
        }
    }, []);

    return (
    <DashboardLayout>
        <DashboardNavbar />
        <MDBox py={3}>
            <Grid item xs={12} md={6} lg={3}>
                <Card>
                    <Grid container spacing={1} pt={1} px={2}>
                       <Grid item xs={4} md={2} lg={1}>
                            <MDBox variant="gradient" bgColor='dark' color='light' coloredShadow='light' borderRadius="xl" display="flex" justifyContent="center" alignItems="center" width="4rem" height="4rem" mt={-3}>
                                    <Icon fontSize="large" color="inherit">
                                        print
                                    </Icon>
                                </MDBox>
                       </Grid>
                       <Grid item xs={8} md={3} lg={7}>
                            <MDBox textAlign="left">
                                    <MDTypography variant="h6" fontWeight="medium">
                                        اطلاعات تکمیلی سفارش
                                    </MDTypography>
                                </MDBox>
                       </Grid>
                       <Grid item xs={12} md={12} lg={4}>
                           <MDBox textAlign="right" display={{xs: 'block', md :'flex' , lg : 'flex'}}  lineHeight={1.25}>
                               <MDTypography variant="h4">
                                   <MDButton
                                       width='100%'
                                       size="small"
                                       endIcon={<SupportAgentIcon fontSize="large" />}
                                       color="warning"
                                   >
                                      09386801868 تماس با پشتیبانی
                                   </MDButton>
                               </MDTypography>
                               <MDTypography mt={1} ml={1} variant="button" fontWeight="light" color="text">
                                        تاریخ سفارش
                               </MDTypography>
                               <MDTypography mt={1} variant="button" fontWeight="light" color="text">
                                        { order.creation_date}
                               </MDTypography>
                           </MDBox>
                       </Grid>
                    </Grid>
                    <Divider />
                    {!canceled ? (
                        <Grid container p={3} spacing={3}>

                            <Grid item xs={12}>
                                {order && order.order_items && order.order_items.length > 0 && (
                                    <Bill
                                        name={client.name && client.lastName ? `${client.name} ${client.lastName}` : ' - '}
                                        mobile={client.mobile}
                                        description={order.description}
                                        orderItems={order.order_items}
                                    />
                                )}
                            </Grid>

                            <Grid item xs={6} lg={6}>
                                {rider ? (
                                    <RiderTimeLine />
                                ) : (
                                    <>
                                        <MDButton fullWidth onClick={creatSnappOrderHandler}        loading variant="contained" color="success" size="medium" endIcon={<DeliveryDiningOutlined />}>
                                            اسنپ و بگیر
                                        </MDButton>
                                    </>
                                )}
                            </Grid>

                            {rider ? ('') :(
                            <Grid item xs={6} lg={6}>
                                <MDButton fullWidth onClick={cancelOrder}        loading variant="contained" color="error" size="medium" endIcon={<Cancel />}>
                                    لغو سفارش
                                </MDButton>
                            </Grid>
                            )}
                        </Grid>
                    ):(
                        <Grid container>

                            <Grid item xs={12} lg={12} pb={3} px={6}>
                                {order && order.order_items && order.order_items.length > 0 && (
                                    <Bill
                                        name={`${client.name} ${client.lastName}`}
                                        mobile={client.mobile}
                                        description={order.description}
                                        file={order.order_items[0].file}
                                        orderItems={order.order_items}
                                    />
                                )}
                            </Grid>

                            <Grid item xs={12} lg={12} pb={3} px={6}>
                                <MDBox display="flex" justifyContent="space-between" p={3} bgColor='error' alignItems={{ xs: "flex-start", sm: "center" }} borderRadius={4}>
                                    <MDTypography variant="caption" color="white">
                                        سفارش لغو شده است.
                                    </MDTypography>
                                </MDBox>
                            </Grid>


                        </Grid>
                    )}

                </Card>
            </Grid>
        </MDBox>
    </DashboardLayout>
  );
}

export default Order;
