import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";

// Data
import orderTableData from "layouts/todoes/data/todoTableData";
import {useEffect, useState} from "react";
import OrderService from "../../services/order-service";


function Todo() {
    const [tableData, setTableData] = useState({ columns: [], rows: [] });


    const fetchTodos = async () => {
      const userData = localStorage.getItem('token'); // Use getItem, not key
      const response = await OrderService.todos(JSON.stringify(userData));

      const { columns, rows } = orderTableData(response.data); 
      setTableData({ columns, rows });
  };


    useEffect(() => {

        fetchTodos();

    }, []);
  const { columns, rows } = tableData;


  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  جدول سفارشات
                </MDTypography>
              </MDBox>
              <MDBox pt={3}>
                <DataTable
                  table={{ columns, rows }}
                  isSorted={false}
                  entriesPerPage={false}
                  showTotalEntries={false}
                  noEndBorder
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default Todo;
