import {useState, useEffect} from "react";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import MDAlert from "components/MDAlert";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Header from "layouts/user-profile/Header";

import {styled} from "@mui/material/styles";
import AuthService from "../../services/auth-service";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import MDmapChooser from "../../components/MDmapChooser";
import Grid from "@mui/material/Grid";
import Swal from "sweetalert2";

const UserProfile = () => {
    const [parentLocation, setParentLocation] = useState({ lat: null, lng: null });

    const [file, setFile] = useState(null);
    const [user, setUser] = useState({
        name: "",
        fullName: "",
        mobile: "",
        summary : "",
        logo: "",
        address: "",
        location: { lat: "", lng: "" },
    });

    const [errors, setErrors] = useState({
        nameError: false,
        fullNameError: false,
        summaryError: false,
        mobileError: false,
    });

    const getUserData = async () => {
        const response = await AuthService.getProfile();
        console.log(response.data.location);
        
        
       let _location = response.data.location;
        response.data.location = _location
        setUser((prevUser) => ({
            ...prevUser,
            ...response.data
        }));

    };
    const fileUploadHandler =  (e) => {
        
        setFile(e.target.files[0])
    };

    useEffect(() => {
        getUserData();

    }, []);



    useEffect(() => {
        setUser((prevUser) => ({
            ...prevUser,
            location: parentLocation,
        }));
    }, [parentLocation]);


    const changeHandler = (e) => {
        setUser({
            ...user,
            [e.target.name]: e.target.value,
        });
    };

    const VisuallyHiddenInput = styled('input')({
        clip: 'rect(0 0 0 0)',
        clipPath: 'inset(50%)',
        height: 1,
        overflow: 'hidden',
        position: 'absolute',
        bottom: 0,
        left: 0,
        whiteSpace: 'nowrap',
        width: 1,
    });

    
    const submitHandler = async (e) => {
        e.preventDefault();
       
        // Create FormData instance
        const userData = new FormData();
    
        // Append user details to FormData
        userData.append('name', user.name);
        userData.append('fullName', user.fullName);
        userData.append('summary', user.summary);
        userData.append('address', user.address);
        userData.append('mobile', user.mobile);
        userData.append('_method', 'PUT');
    
        // Append logo if it exists
        if (file) {
            userData.append('logo', file);
        }

        
        if (user.location) {
           
            userData.append(`location[lat]`, user.location.lat);
            userData.append(`location[lng]`, user.location.lng);
        }
    
        try {
            // Call API for update
            const response = await AuthService.updateProfile(userData);
            if (response.error) {
                
                Swal.fire({
                    title: "خطا!",
                    text: response.message,
                    icon: "error"
                  });
        
            } else {
                // Reset errors
                setErrors({
                    nameError: false,
                    fullNameError: false,
                    mobileError: false,
                    addressError: false,
                    locationError: false,
                    passwordError: false,
                    newPassError: false,
                    confirmPassError: false,
                });
    
                Swal.fire({
                    title: "",
                    text: 'پروفایل شما با موفقیت به روزرسانی شد',
                    icon: "success"
                  });

        
            }
        } catch (error) {
            Swal.fire({
                title: "خطا",
                text: 'خطا در به روز رسانی پروفایل!',
                icon: "success"
              });
        }
    };

    

    const handleLocationChange = (location) => {
        setParentLocation(location);
    }

    return (

        <DashboardLayout>
            <DashboardNavbar/>
            <MDBox mb={2}/>
            <Header name={user.fullName} image={user.logo}>

                    <MDBox>
                        <Grid container spacing={3}>
                            <Grid item xs={12} md={6} lg={3}>
                                <MDBox  >
                                        <MDTypography variant="body2" color="text" ml={1} fontWeight="regular">
                                        نام و نام خانوادگی
                                        </MDTypography>
                                        <MDBox mb={2} width="100%">
                                            <MDInput
                                                type="fullName"
                                                fullWidth
                                                name="fullName"
                                                value={user.fullName}
                                                onChange={changeHandler}
                                                error={errors.fullNameError}
                                            />
                                            {errors.nameError && (
                                                <MDTypography variant="caption" color="error" fontWeight="light">
                                                    نام نمیتواند خالی باشد
                                                </MDTypography>
                                            )}
                                        </MDBox>
                                    </MDBox>
                            </Grid>
                            <Grid item xs={12} md={6} lg={3}>
                                <MDBox  >
                                        <MDTypography variant="body2" color="text" ml={1} fontWeight="regular">
                                            نام دفتر فنی
                                        </MDTypography>
                                        <MDBox mb={2} width="100%">
                                            <MDInput
                                                type="name"
                                                fullWidth
                                                name="name"
                                                value={user.name}
                                                onChange={changeHandler}
                                                error={errors.nameError}
                                            />
                                            {errors.nameError && (
                                                <MDTypography variant="caption" color="error" fontWeight="light">
                                                    نام دفتر فنی نمیتواند خالی باشد
                                                </MDTypography>
                                            )}
                                        </MDBox>
                                    </MDBox>
                            </Grid>
                        
                            <Grid item xs={12} md={6} lg={3}>
                                <MDBox  >
                                        <MDTypography variant="body2" color="text" ml={1} fontWeight="regular">
                                            توضیحات
                                        </MDTypography>
                                        <MDBox mb={2} width="100%">
                                            <MDInput
                                                type="summary"
                                                fullWidth
                                                name="summary"
                                                value={user.summary}
                                                onChange={changeHandler}
                                                error={errors.summaryError}
                                            />
                                            {errors.summaryError && (
                                                <MDTypography variant="caption" color="error" fontWeight="light">
                                                    توضیحات دفتر فنی نمیتواند خالی باشد
                                                </MDTypography>
                                            )}
                                        </MDBox>
                                    </MDBox>
                            </Grid>
                    
                            <Grid item xs={12} md={6} lg={3}>
                                <MDBox >
                                        <MDTypography variant="body2" color="text" ml={1} fontWeight="regular">
                                            شماره تماس
                                        </MDTypography>
                                        <MDBox mb={1} width="100%">
                                            <MDInput
                                                type="mobile"
                                                fullWidth
                                                name="mobile"
                                                value={user.mobile}
                                                onChange={changeHandler}
                                                error={errors.mobileError}
                                            />
                                            {errors.mobileError && (
                                                <MDTypography variant="caption" color="error" fontWeight="light">
                                                    شماره تماس نمیتواند خالی باشد
                                                </MDTypography>
                                            )}
                                        </MDBox>
                                    </MDBox>
                            </Grid>
                             <Grid item xs={12} md={6} lg={9}>
                                <MDBox >
                                    <MDTypography variant="body2" color="text" ml={1} fontWeight="regular">
                                        آدرس
                                    </MDTypography>
                                    <MDBox mb={2} width="100%">
                                        <MDInput
                                            type="text"
                                            fullWidth
                                            name="address"
                                            placeholder="Address"
                                            value={user.address}
                                            onChange={changeHandler}
                                            error={errors.addressError}
                                            inputProps={{
                                                autoComplete: "new-address",
                                                form: {
                                                    autoComplete: "off",
                                                },
                                            }}
                                        />
                                        {errors.newPassError && (
                                            <MDTypography variant="caption" color="error" fontWeight="light">
                                                آدرس نمیتواند خالی باشد
                                            </MDTypography>
                                        )}
                                    </MDBox>
                                </MDBox>
                           </Grid>
                           <Grid item xs={12} md={6} lg={3}>
                                <MDBox px={2}>
                                        <MDTypography variant="body2" color="text" mb={1} ml={1} fontWeight="regular">
                                        لوگو خود را انتخاب کنید
                                        </MDTypography>
                                    <MDBox display='flex'>
                                        <MDButton component="label" variant="contained" color={file ? 'success' : 'info' } startIcon={<CloudUploadIcon />}>
                                            بارگزاری
                                            <VisuallyHiddenInput onChange={fileUploadHandler} type="file"/>
                                        </MDButton>
                                        {file && (
                                            <MDButton component="label" variant="text" color="info" >
                                                    <MDTypography variant="comment" color="text" mb={1} ml={1} fontWeight="regular">
                                                        {file.name} 
                                                    </MDTypography>
                                                </MDButton>
                                        )}
                                    </MDBox>
                                </MDBox>
                            </Grid>
                           <Grid item xs={12} md={12} lg={12}>
                                <MDBox >
                                    <MDTypography variant="body2" color="text" ml={1} fontWeight="regular">
                                        لوکیشن
                                    </MDTypography>
                                    <MDBox mb={2} width="100%">
                                        {/*<MDTypography>{parentLocation.lat}*/}
                                        {/*</MDTypography>*/}

                                        <MDmapChooser location={user.location} onLocationChange={handleLocationChange}/>
                                    </MDBox>
                                </MDBox>
                            </Grid>
                           <Grid item xs={0} md={6} lg={9}>
                            </Grid>
                           <Grid item xs={12} md={6} lg={3}>
                               <MDButton variant="gradient" 
                                    onClick={submitHandler} fullWidth color="info" type="submit" loading>
                                   ذخیره
                               </MDButton>
                            </Grid>
                        </Grid>
                    </MDBox>

            </Header>

        </DashboardLayout>
    );
};

export default UserProfile;
