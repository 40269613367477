import HttpService from "./htttp.service";

class SnappOrderService {

  creatSnappOrder = async (payload, id) => {
    const creatSnappOrderEndpoint = 'snapp-order/'+id;
    return await HttpService.post(creatSnappOrderEndpoint, payload);
  };
  cancelSnappOrder = async (payload, id) => {
    const cancelSnappOrderEndpoint = 'snapp-order/cancel/'+id;
    return await HttpService.post(cancelSnappOrderEndpoint, payload);
  };
  getPriceSnappOrder = async (payload) => {
    const getPriceSnappOrderEndpoint = 'snapp-order/price';
    return await HttpService.post(getPriceSnappOrderEndpoint, payload);
  };
  getStatusSnappOrder = async (payload, id) => {
    const getStatusSnappOrderEndpoint = 'snapp-order/status/'+id;
    return await HttpService.post(getStatusSnappOrderEndpoint, payload);
  };

}

export default new SnappOrderService();
